<template>
  <el-descriptions class="margin-top" :column="3" size="">
    <el-descriptions-item label="指令名称"
      ><span style="color: #fff !important">{{
        v.name
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="站点ID"
      ><span style="color: #fff !important">{{
        v.siteId
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="下发时间"
      ><span style="color: #ccc !important">{{
        v.createTime
      }}</span></el-descriptions-item
    >
    <template v-for="(item, index) in contentList[i]">
      <el-descriptions-item :label="item.name" :key="index">{{
        item.value
      }}</el-descriptions-item>
    </template>
  </el-descriptions>
</template>

<script>
export default {
  props: {
    i: {
      type: Number,
      default() {
        return 0;
      },
    },
    v: {
      type: Object,
      default() {
        return {};
      },
    },
    contentList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    contentList(newVal) {
      this.contentList = newVal;
      console.log(newVal);
    },
  },
};
</script>
