<template>
  <div class="container-box">
    <div class="name">{{ name }}</div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      // 标题
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.container-box {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  /* border-bottom: 1px solid #dddddd; */
  /* box-shadow: 0 0 3px #12ebfb; */
  border-radius: none !important;
  /* border-bottom: 1px solid #12ebfb; */
}
.name {
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
  box-sizing: border-box;
  border-left: 3px solid #12ebfb;
  color: #fff;
}
</style>
