<template>
  <el-dialog
    title="账单推送确认"
    :visible.sync="visible"
    width="35%"
    @close="close"
  >
    <div class="rate-box">
      <!-- <lable-name name="费率信息"></lable-name> -->
      <div class="rate-info">
        <el-descriptions class="margin-top" :column="4" size="">
          <el-descriptions-item label="高峰充电量"
            ><span style="color: #ccc !important">{{
              Obj.highChargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="高峰放电量"
            ><span style="color: #ccc !important">{{
              Obj.highDischargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="高峰电价"
            ><span style="color: #ccc !important">{{
              Obj.highElectricityPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="高峰充放总费用"
            ><span style="color: #ccc !important">{{
              Obj.highAllPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="高峰充放折扣后费用"
            ><span style="color: #ccc !important">{{
              Obj.highDiscountPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="平期充电量"
            ><span style="color: #ccc !important">{{
              Obj.normalChargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="平期放电量"
            ><span style="color: #ccc !important">{{
              Obj.normalDischargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="平期电价"
            ><span style="color: #ccc !important">{{
              Obj.normalElectricityPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="平期充放总费用"
            ><span style="color: #ccc !important">{{
              Obj.normalAllPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="平期充放折扣后费用"
            ><span style="color: #ccc !important">{{
              Obj.normalDiscountPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="谷期充电量"
            ><span style="color: #ccc !important">{{
              Obj.lowChargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="谷期放电量"
            ><span style="color: #ccc !important">{{
              Obj.lowDischargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="谷期电价"
            ><span style="color: #ccc !important">{{
              Obj.lowElectricityPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="谷期充放总费用"
            ><span style="color: #ccc !important">{{
              Obj.lowAllPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="谷期充放折扣后费用"
            ><span style="color: #ccc !important">{{
              Obj.lowDiscountPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >

          <el-descriptions-item label="尖峰充电量"
            ><span style="color: #ccc !important">{{
              Obj.sharpChargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="尖峰放电量"
            ><span style="color: #ccc !important">{{
              Obj.sharpDischargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="尖峰电价"
            ><span style="color: #ccc !important">{{
              Obj.sharpElectricityPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="尖峰充放总费用"
            ><span style="color: #ccc !important">{{
              Obj.sharpAllPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="尖峰充放折扣后费用"
            ><span style="color: #ccc !important">{{
              Obj.sharpDiscountPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >

          <el-descriptions-item label="高尖峰充电量"
            ><span style="color: #ccc !important">{{
              Obj.highSharpChargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="高尖峰放电量"
            ><span style="color: #ccc !important">{{
              Obj.highSharpDischargeNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="高尖峰电价"
            ><span style="color: #ccc !important">{{
              Obj.highSharpElectricityPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="高尖峰充放总费用"
            ><span style="color: #ccc !important">{{
              Obj.highSharpAllPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="高尖峰充放折扣后费用"
            ><span style="color: #ccc !important">{{
              Obj.highSharpDiscountPrice | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="甲方"
            ><span style="color: #fff !important">{{
              Obj.companyA
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="甲方抄表员"
            ><span style="color: #fff !important">{{
              Obj.readMeterUserA
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="抄表日期"
            ><span style="color: #fff !important">{{
              Obj.readMeterDate
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="用电天数"
            ><span style="color: #fff !important">{{ Obj.usePowerTime }}</span
            ><span class="unit">天</span></el-descriptions-item
          >
          <el-descriptions-item label="乙方"
            ><span style="color: #fff !important">{{
              Obj.readMeterUserB
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="乙方抄表员"
            ><span style="color: #fff !important">{{
              Obj.readMeterUserA
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="投资比例"
            ><span style="color: #fff !important">{{
              Obj.investorRate + "%"
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="期间"
            ><span style="color: #fff !important">{{
              Obj.readMeterUserA
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="初次读数"
            ><span style="color: #fff !important">{{
              Obj.beginReadNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          ><el-descriptions-item label="今次读数"
            ><span style="color: #fff !important">{{
              Obj.endReadNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="计费电量"
            ><span style="color: #fff !important">{{
              Obj.totalPowerNum | num
            }}</span
            ><span class="unit">kWh</span></el-descriptions-item
          >
          <el-descriptions-item label="其他节能收益"
            ><span style="color: #fff !important">{{
              Obj.otherEconomyBenefit | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          ><el-descriptions-item label="本期节能效益合计"
            ><span style="color: #fff !important">{{
              Obj.curEconomyBenefit | num
            }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="应付费用"
            ><span style="color: #fff !important">{{ Obj.payMoney | num }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
        </el-descriptions>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="dialogVisible = false" type="info" size="mini"
            >返回</el-button
            > -->
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit()"
        v-if="Obj.status == 0"
        >确认</el-button
      >
      <!-- <el-button type="primary" size="mini" v-else>修改</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import { deepClone } from "@/utils/deepClone";
import { setOrderPush } from "@/api/message.js";
export default {
  data() {
    return {
      visible: false,
      Obj: {},
      id: -1,
    };
  },
  mounted() {},
  methods: {
    open(data) {
      console.log(data.id);
      this.visible = true;
      this.Obj = deepClone(data);
      this.id = data.id;
      // this.rateObj.rateTime = JSON.parse(this.rateObj.rateTime);
    },
    close() {
      this.visible = false;
    },
    onSubmit() {
      setOrderPush({ id: this.id }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "账单已推送",
          });
          this.$emit("refreshBill");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
  },
  filters: {
    num: (val, fix = 2) => {
      if (val < 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = Math.abs(val);
        val = "" + val; // 转换成字符串
        console.log("llllll", val);
        var int = val.slice(0, fix * -1 - 1); // 拿到整数
        var ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        var temp = ""; // 临时变量
        for (var i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return "-" + temp; // 返回
      } else if (val != "-" && val != "" && val != null && val > 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = "" + val; // 转换成字符串
        int = val.slice(0, fix * -1 - 1); // 拿到整数
        ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        temp = ""; // 临时变量
        for (i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return temp; // 返回
      } else if (val == 0 || val == "0") {
        return val;
      } else {
        return "-";
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 60% !important
}
/deep/ .el-select{
  width 60% !important
}
/deep/ .el-select .el-input{
  width 100% !important
}
/deep/ .el-dialog, .el-message-box{
  min-width 1040px !important
}
.unit{
  padding-left 20px
}
</style>
