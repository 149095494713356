import { render, staticRenderFns } from "./rateDetail.vue?vue&type=template&id=408e82b8&scoped=true&"
import script from "./rateDetail.vue?vue&type=script&lang=js&"
export * from "./rateDetail.vue?vue&type=script&lang=js&"
import style0 from "./rateDetail.vue?vue&type=style&index=0&id=408e82b8&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408e82b8",
  null
  
)

export default component.exports