import { http } from "../utils/http";

export const cmdGetRequestList = (data) =>
  http.post("/weilan/api/site/info/cmdGetRequestList", data);

export const getCmdRequstCount = (data) =>
  http.post("/weilan/api/site/info/getCmdRequstCount", data);

export const billQuery = (data) =>
  http.post("/weilan/api/user/bill/query", data);

export const getOrderCount = (data) =>
  http.post("/weilan/api/user/bill/getOrderCount", data);

export const cmdSend = (data) =>
  http.post("/weilan/api/site/info/cmdSend", data);

export const setOrderPush = (data) =>
  http.post("/weilan/api/user/bill/setOrderPush", data);
