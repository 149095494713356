<template>
  <el-descriptions class="margin-top" :column="3" size="">
    <el-descriptions-item label="账单月份"
      ><span style="color: #fff !important">{{
        v.billMonth
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="站点ID"
      ><span style="color: #fff !important">{{
        v.siteName
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="客户名称"
      ><span style="color: #ccc !important">{{
        v.companyB
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="分成方式"
      ><span style="color: #ccc !important">{{
        v.investorWay
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="储能效益"
      ><span style="color: #ccc !important">{{
        v.curEconomyBenefit
      }}</span></el-descriptions-item
    >
    <el-descriptions-item label="应付费用"
      ><span style="color: #ccc !important">{{
        v.payMoney
      }}</span></el-descriptions-item
    >
  </el-descriptions>
</template>

<script>
export default {
  props: {
    v: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
