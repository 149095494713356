<template>
  <el-dialog
    title="费率下发确认"
    :visible.sync="visible"
    width="35%"
    @close="close"
  >
    <div class="rate-box">
      <!-- <lable-name name="费率信息"></lable-name> -->
      <div class="rate-info">
        <el-descriptions class="margin-top" :column="4" size="">
          <el-descriptions-item label="费率名称"
            ><span style="color: #fff !important">{{
              rateObj.name
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="年尖峰月份"
            ><span style="color: #fff !important">{{
              rateObj.topPeakMonths.join(",")
            }}</span></el-descriptions-item
          >
          <el-descriptions-item label="高峰电价"
            ><span style="color: #ccc !important">{{ rateObj.fee2 | num }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="平期电价"
            ><span style="color: #ccc !important">{{ rateObj.fee3 | num }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="谷期电价"
            ><span style="color: #ccc !important">{{ rateObj.fee4 | num }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="尖峰电价"
            ><span style="color: #ccc !important">{{
              rateObj.fee1 | num
            }}</span>
            <span class="unit">元</span></el-descriptions-item
          >
          <el-descriptions-item label="高尖峰电价"
            ><span style="color: #ccc !important">{{ rateObj.fee5 | num }}</span
            ><span class="unit">元</span></el-descriptions-item
          >
        </el-descriptions>
        <div class="time-info">
          <el-table :data="rateObj.periodFeeConfigs">
            <el-table-column label="开始时间" prop="startTime">
            </el-table-column>
            <el-table-column label="结束时间" prop="endTime"> </el-table-column>
            <el-table-column label="时段信息" prop="periodType">
              <template slot-scope="scope">
                {{ timeType[scope.row.periodType] }}
              </template>
            </el-table-column>
            <el-table-column label="充放功率" prop="controlMode">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit()"
        v-if="!status && userStatus"
        >确认下发</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { deepClone } from "@/utils/deepClone";
import { cmdSend } from "@/api/message.js";
export default {
  data() {
    return {
      visible: false,
      rateObj: {},
      status: 0,
      userStatus: false,
      id: -1,
      chargeStatus: ["空闲", "充电", "放电"],
      timeType: ["", "尖峰", "高峰", "平期", "谷期", "高尖峰"],
    };
  },
  mounted() {},
  methods: {
    open(data, status, userStatus, id) {
      console.log(data, status, userStatus, id);
      this.visible = true;
      this.rateObj = deepClone(data);
      this.status = status;
      this.userStatus = userStatus;
      this.id = id;
    },
    close() {
      this.visible = false;
    },
    onSubmit() {
      cmdSend({ id: this.id }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "命令下发成功",
          });
          this.visible = false;
          this.$emit("refreshCmd");
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
  },
  filters: {
    num: (val, fix = 2) => {
      if (val < 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = Math.abs(val);
        val = "" + val; // 转换成字符串
        console.log("llllll", val);
        var int = val.slice(0, fix * -1 - 1); // 拿到整数
        var ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        var temp = ""; // 临时变量
        for (var i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return "-" + temp; // 返回
      } else if (val != "-" && val != "" && val != null && val > 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = "" + val; // 转换成字符串
        int = val.slice(0, fix * -1 - 1); // 拿到整数
        ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        temp = ""; // 临时变量
        for (i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return temp; // 返回
      } else if (val == 0 || val == "0") {
        return val;
      } else {
        return "-";
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 60% !important
}
/deep/ .el-select{
  width 60% !important
}
/deep/ .el-select .el-input{
  width 100% !important
}
/deep/ .el-dialog, .el-message-box{
  min-width 1040px !important
}
.unit{
  padding-left 20px
}
</style>
