<template>
  <div class="message-box padding-md text-white">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="allOrderTitle" name="first">
        <div class="all-message" v-if="allBillMsg.length > 0">
          <div
            class="message-content flex"
            v-for="(v, i) in allBillMsg"
            :key="i"
          >
            <div>
              <lable-name name="账单推送确认"></lable-name>
              <div class="message-list flex items-middle" :key="i">
                <div class="left">
                  <div class="img">
                    <img src="@/assets/imgs/msg4.png" />
                  </div>
                </div>
                <div class="middle">
                  <!-- <div class="name">{{ v.name }}</div> -->
                  <div class="content">
                    <bill-list :v="v"></bill-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="status" v-if="v.status" style="top: -75px; left: 0px">
                <icon type="icon-yichuli1" :size="100" color="green"></icon>
              </div>
              <div class="status" v-else>
                <icon type="icon-weichuli" :size="100" color="red"></icon>
              </div>
              <div class="handle-button">
                <el-button type="text" @click="viewBill(v)">查看详情</el-button>
                <el-button
                  class="btn-purple"
                  @click="submitBill(v)"
                  size="mini"
                  v-preventReClick
                  v-if="!v.status"
                  >账单推送</el-button
                >
              </div>
            </div>
          </div>
          <div class="block" style="margin-top: 15px">
            <pagination
              :total="allOrderNum"
              v-on:getCurrent="changeTotalCurrent"
              v-on:getPageSize="changeTotalPage"
            ></pagination>
          </div>
        </div>
        <no-data v-else></no-data>
      </el-tab-pane>
      <el-tab-pane :label="unOrderCmdTitle" name="second">
        <div class="all-message" v-if="unSendBill.length > 0">
          <div
            class="message-content flex"
            v-for="(v, i) in unSendBill"
            :key="i"
          >
            <div>
              <lable-name name="账单推送确认"></lable-name>
              <div class="message-list flex items-middle" :key="i">
                <div class="left">
                  <div class="img">
                    <img src="@/assets/imgs/msg4.png" />
                  </div>
                </div>
                <div class="middle">
                  <div class="content">
                    <bill-list :v="v"></bill-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div
                class="status"
                v-if="v.status"
                style="top: -90px; left: -50px"
              >
                <icon type="icon-yichuli1" :size="100" color="green"></icon>
              </div>
              <div class="status" v-else>
                <icon type="icon-weichuli" :size="100" color="red"></icon>
              </div>
              <div class="handle-button">
                <el-button type="text" @click="viewBill(v)">查看详情</el-button>
                <el-button
                  class="btn-purple"
                  @click="submitBill(v)"
                  size="mini"
                  v-preventReClick
                  v-if="!v.status"
                  >账单推送</el-button
                >
              </div>
            </div>
          </div>
          <div class="block" style="margin-top: 15px">
            <pagination
              :total="allOrderNum"
              v-on:getCurrent="changeTotalCurrent"
              v-on:getPageSize="changeTotalPage"
            ></pagination>
          </div>
        </div>
        <no-data v-else></no-data>
      </el-tab-pane>
      <el-tab-pane :label="allCmdTitle" name="third">
        <div class="all-message" v-if="allMsg.length > 0">
          <div class="message-content flex" v-for="(v, i) in allMsg" :key="i">
            <div>
              <lable-name name="指令下发确认"></lable-name>
              <div class="message-list flex items-middle" :key="i">
                <div class="left">
                  <div class="img">
                    <img src="@/assets/imgs/msg3.png" />
                  </div>
                </div>
                <div class="middle">
                  <!-- <div class="name">{{ v.name }}</div> -->
                  <div class="content">
                    <message-list
                      :v="v"
                      :contentList="contentList"
                      :i="i"
                    ></message-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div
                class="status"
                v-if="v.status"
                style="top: -90px; left: -50px"
              >
                <icon type="icon-yichuli1" :size="100" color="green"></icon>
              </div>
              <div class="status" v-else>
                <icon type="icon-weichuli" :size="100" color="red"></icon>
              </div>
              <div class="handle-button">
                <el-button
                  type="text"
                  v-show="v.name === '设置费率'"
                  @click="viewDetail(v)"
                  >查看详情</el-button
                >
                <el-button
                  class="btn-purple"
                  @click="submitCmd(v)"
                  size="mini"
                  v-preventReClick
                  v-if="!v.status && userId != v.userId"
                  >命令下发</el-button
                >
              </div>
            </div>
          </div>
          <div class="block" style="margin-top: 15px">
            <pagination
              :total="allCmdNum"
              v-on:getCurrent="changeTotalCurrent"
              v-on:getPageSize="changeTotalPage"
            ></pagination>
          </div>
        </div>
        <no-data v-else></no-data>
      </el-tab-pane>
      <el-tab-pane :label="unSendCmdTitle" name="fourth">
        <div class="all-message" v-if="unSendList.length > 0">
          <div
            class="message-content flex"
            v-for="(v, i) in unSendList"
            :key="i"
          >
            <div>
              <lable-name name="指令下发确认"></lable-name>
              <div class="message-list flex items-middle" :key="i">
                <div class="left">
                  <div class="img">
                    <img src="@/assets/imgs/msg3.png" />
                  </div>
                </div>
                <div class="middle">
                  <!-- <div class="name">{{ v.name }}</div> -->
                  <div class="content">
                    <message-list
                      :v="v"
                      :contentList="contentList1"
                      :i="i"
                    ></message-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="status">
                <icon type="icon-weichuli" :size="100" color="red"></icon>
              </div>
              <div class="handle-button">
                <el-button
                  type="text"
                  v-show="v.name === '设置费率'"
                  @click="viewDetail(v)"
                  >查看详情</el-button
                >
                <el-button
                  class="btn-purple"
                  @click="submitCmd(v)"
                  size="mini"
                  v-preventReClick
                  v-if="!v.status && userId != v.userId"
                  >命令下发</el-button
                >
              </div>
            </div>
          </div>
          <div class="block" style="margin-top: 15px">
            <pagination
              :total="unSendCmdNum"
              v-on:getCurrent="changeUnSendCurrent"
              v-on:getPageSize="changeUnSendPage"
            ></pagination>
          </div>
        </div>
        <no-data v-else></no-data>
      </el-tab-pane>
      <el-tab-pane :label="haveCmdTitle" name="five"
        ><div class="all-message" v-if="haveSendList.length > 0">
          <div
            class="message-content flex"
            v-for="(v, i) in haveSendList"
            :key="i"
          >
            <div>
              <lable-name name="指令下发确认"></lable-name>
              <div class="message-list flex items-middle" :key="i">
                <div class="left">
                  <div class="img">
                    <img src="@/assets/imgs/msg3.png" />
                  </div>
                </div>
                <div class="middle">
                  <!-- <div class="name">{{ v.name }}</div> -->
                  <div class="content">
                    <message-list
                      :v="v"
                      :contentList="contentList2"
                      :i="i"
                    ></message-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="status" style="top: -90px; left: -50px">
                <icon type="icon-yichuli1" :size="100" color="green"></icon>
              </div>
              <!-- <div class="handle-button">
                <el-button
                  class="btn-purple"
                  @click="handleBtn(i)"
                  size="mini"
                  v-preventReClick
                  v-if="!v.status && userId != v.userId"
                  >命令下发</el-button
                >
              </div> -->
            </div>
          </div>
          <div class="block" style="margin-top: 15px">
            <pagination
              :total="haveSendCmdNum"
              v-on:getCurrent="changeHaveSendCurrent"
              v-on:getPageSize="changeHaveSendPage"
            ></pagination>
          </div>
        </div>
        <no-data v-else></no-data
      ></el-tab-pane>
    </el-tabs>
    <rate-detail ref="rateDetail"></rate-detail>
    <bill-detail ref="billDetail"></bill-detail>
  </div>
</template>
<script>
import noData from "../../components/noData.vue";
import {
  cmdGetRequestList,
  getCmdRequstCount,
  billQuery,
  getOrderCount,
  cmdSend,
  setOrderPush,
} from "@/api/message.js";
import Pagination from "../../components/pagination.vue";
import LableName from "../../components/LableName.vue";
import Icon from "../../components/Icon.vue";
import RateDetail from "./components/rateDetail.vue";
import MessageList from "./components/messageList.vue";
import BillList from "./components/billList.vue";
import BillDetail from "./components/billDetail.vue";
export default {
  components: {
    noData,
    Pagination,
    LableName,
    Icon,
    RateDetail,
    MessageList,
    BillList,
    BillDetail,
  },
  data() {
    return {
      activeName: "first",
      userId: -1,
      allMsg: [],
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      unSendObject: {
        pageNumber: 1,
        pageSize: 10,
        status: 0,
      },
      haveSendObject: {
        pageNumber: 1,
        pageSize: 10,
        status: 1,
      },
      allCmdNum: 0,
      haveSendCmdNum: 0,
      unSendCmdNum: 0,
      allCmdTitle: "全部指令消息",
      allOrderTitle: "全部账单消息",
      unSendCmdTitle: "未处理指令消息",
      unOrderCmdTitle: "未处理账单消息",
      haveCmdTitle: "已处理指令消息",
      haveOrderTitle: "已处理账单消息",
      contentList: [],
      haveSendList: [],
      contentList1: [],
      unSendList: [],
      contentList2: [],

      allBillMsg: [],
      unSendBill: [],
      haveSendOrderNum: 0,
      allOrderNum: 0,
      unSendOrderNum: 0,
    };
  },
  mounted() {
    this.getCount();
    this.getAllBill();
  },
  methods: {
    submitCmd(data) {
      console.log(data);
      // if(data.jsonData.cmd=="setFeeConfig"){
      //   data.jsonData.data
      // }
      cmdSend({ id: data.id }).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "命令下发成功",
          });
          this.getMessage();
          this.getUnSend();
          this.getCount();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    submitBill(data) {
      console.log(data);
      setOrderPush({ id: data.id }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "账单已推送",
          });
          this.getAllBill();
          this.getUnSendBill();
          this.getCount();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getCount() {
      this.userId = localStorage.getItem("stewardUserId");
      getCmdRequstCount({}).then((res) => {
        // console.log(res.data.data);
        this.allCmdTitle = "全部指令消息";

        this.unSendCmdTitle = "未处理指令消息";

        this.haveCmdTitle = "已处理指令消息";
        this.haveOrderTitle = "已处理账单消息";
        this.allCmdNum = res.data.data.allCmdNum;
        this.haveSendCmdNum = res.data.data.haveSendCmdNum;
        this.unSendCmdNum = res.data.data.unSendCmdNum;
        this.unSendCmdTitle =
          this.unSendCmdTitle + "（" + this.unSendCmdNum + "）";
        this.allCmdTitle = this.allCmdTitle + "（" + this.allCmdNum + "）";
        this.haveCmdTitle =
          this.haveCmdTitle + "（" + this.haveSendCmdNum + "）";
      });
      getOrderCount({}).then((res) => {
        console.log(res.data.data);
        this.allOrderTitle = "全部账单消息";
        this.unOrderCmdTitle = "未处理账单消息";
        this.allOrderNum = res.data.data.allOrderNum;
        this.haveSendOrderNum = res.data.data.haveSendOrderNum;
        this.unSendOrderNum = res.data.data.unSendOrderNum;
        this.unOrderCmdTitle =
          this.unOrderCmdTitle + "（" + this.unSendOrderNum + "）";
        this.allOrderTitle =
          this.allOrderTitle + "（" + this.allOrderNum + "）";
        this.haveOrderTitle =
          this.haveOrderTitle + "（" + this.haveSendOrderNum + "）";
      });
    },
    handleClick(tab, event) {
      // console.log(tab.index, "jjjjj", event);
      this.getCount();
      if (tab.index == 0) {
        // console.log(tab.index, "jjjj55555j", event);
        this.getAllBill();
      } else if (tab.index == 1) {
        this.getUnSendBill();
      } else if (tab.index == 2) {
        this.getMessage();
      } else if (tab.index == 3) {
        this.getUnSend();
      } else if (tab.index == 4) {
        this.getHaveSend();
      }
    },
    getMessage() {
      cmdGetRequestList(this.object).then((res) => {
        this.allMsg = res.data.data.list;
        // this.allCmdNum = this.allMsg.length;
        if (this.allMsg.length > 0) {
          this.allMsg.forEach((v, i) => {
            v.jsonData = JSON.parse(v.jsonData);
            if (v.jsonData.cmd == "setPasswd") {
              let temp = [];
              let obj = {
                name: "",
                value: "",
              };
              // console.log(v.jsonData);
              if (
                Object.prototype.hasOwnProperty.call(
                  v.jsonData.data,
                  "newPasswd"
                )
              ) {
                (obj.name = "新密码"), (obj.value = v.jsonData.data.newPasswd);
              }

              temp.push(obj);
              this.contentList[i] = temp;
            } else if (v.jsonData.cmd == "setToleranceMaxPower") {
              let temp = [];
              let obj = {
                name: "容差",
                value: v.jsonData.data.tolerance,
              };
              // (obj.name = "容差"), (obj.value = v.jsonData.data.tolerance);
              temp.push(obj);
              let obj1 = {
                name: "场站最大功率",
                value: v.jsonData.data.maxPower,
              };
              // (obj.name = "场站最大功率"),
              //   (obj.value = v.jsonData.data.maxPower);
              temp.push(obj1);
              this.contentList[i] = temp;
            } else if (v.jsonData.cmd == "setPowerOutageDate") {
              let temp = [];
              let obj = {
                name: "",
                value: "",
              };
              (obj.name = "限电日期"),
                (obj.value = v.jsonData.data.powerOutageDate);
              temp.push(obj);
              this.contentList[i] = temp;
            } else if (v.jsonData.cmd == "setDischargeChargePower") {
              let temp = [];
              let obj = {
                name: "限电允许用电功率",
                value: v.jsonData.data.dischargePower,
              };
              // (obj.name = "限电允许用电功率"),
              //   (obj.value = v.jsonData.data.usePower);
              temp.push(obj);
              let obj1 = {
                name: "限电最大充电功率",
                value: v.jsonData.data.chargePower,
              };
              // (obj.name = "限电最大充电功率"),
              //   (obj.value = v.jsonData.data.maxChargePower);
              temp.push(obj1);
              this.contentList[i] = temp;
            } else if (v.jsonData.cmd == "setFeeConfig") {
              console.log("setFeeConfig", v.jsonData.data);
              let temp = [];
              // let obj = {
              //   name: "费率标准",
              //   value: v.jsonData.data.rateId.name,
              // };
              // temp.push(obj);
              let obj1 = {
                name: "尖峰月份",
                value: v.jsonData.data.topPeakMonths.join(","),
              };
              // (obj1.name = "尖峰月份"),
              //   (obj1.value = v.jsonData.data.rateId.sharpTimeMonth);
              temp.push(obj1);
              // (obj2.name = "生效日期"),
              //   (obj2.value = v.jsonData.data.rateId.date);
              // console.log(v.jsonData, "v.jsonData.data.rateId.date");
              let obj2 = {
                name: "生效日期",
                value: v.jsonData.data.effectiveTime,
              };
              temp.push(obj2);
              this.contentList[i] = temp;
            }
          });
        }
      });
    },
    getUnSend() {
      cmdGetRequestList(this.unSendObject).then((res) => {
        this.unSendList = res.data.data.list;
        // this.allCmdNum = this.allMsg.length;
        this.unSendList.forEach((v, i) => {
          v.jsonData = JSON.parse(v.jsonData);
          if (v.jsonData.cmd == "setPasswd") {
            let temp = [];
            let obj = {
              name: "",
              value: "",
            };
            // console.log(v.jsonData);
            if (
              Object.prototype.hasOwnProperty.call(v.jsonData.data, "newPasswd")
            ) {
              (obj.name = "新密码"), (obj.value = v.jsonData.data.newPasswd);
            }

            temp.push(obj);
            this.contentList1[i] = temp;
          } else if (v.jsonData.cmd == "setToleranceMaxPower") {
            let temp = [];
            let obj = {
              name: "容差",
              value: v.jsonData.data.tolerance,
            };
            // (obj.name = "容差"), (obj.value = v.jsonData.data.tolerance);
            temp.push(obj);
            let obj1 = {
              name: "场站最大功率",
              value: v.jsonData.data.maxPower,
            };
            // (obj.name = "场站最大功率"),
            //   (obj.value = v.jsonData.data.maxPower);
            temp.push(obj1);
            this.contentList1[i] = temp;
          } else if (v.jsonData.cmd == "setPowerOutageDate") {
            let temp = [];
            let obj = {
              name: "",
              value: "",
            };
            (obj.name = "限电日期"),
              (obj.value = v.jsonData.data.powerOutageDate);
            temp.push(obj);
            this.contentList1[i] = temp;
          } else if (v.jsonData.cmd == "setDischargeChargePower") {
            let temp = [];
            let obj = {
              name: "限电允许用电功率",
              value: v.jsonData.data.dischargePower,
            };
            // (obj.name = "限电允许用电功率"),
            //   (obj.value = v.jsonData.data.usePower);
            temp.push(obj);
            let obj1 = {
              name: "限电最大充电功率",
              value: v.jsonData.data.chargePower,
            };
            // (obj.name = "限电最大充电功率"),
            //   (obj.value = v.jsonData.data.maxChargePower);
            temp.push(obj1);
            this.contentList1[i] = temp;
          } else if (v.jsonData.cmd == "setFeeConfig") {
            let temp = [];
            // let obj = {
            //   name: "费率标准",
            //   value: v.jsonData.data.rateId.name,
            // };
            // temp.push(obj);
            let obj1 = {
              name: "尖峰月份",
              value: v.jsonData.data.topPeakMonths.join(","),
            };
            // (obj1.name = "尖峰月份"),
            //   (obj1.value = v.jsonData.data.rateId.sharpTimeMonth);
            temp.push(obj1);
            // (obj2.name = "生效日期"),
            //   (obj2.value = v.jsonData.data.rateId.date);
            let obj2 = {
              name: "生效日期",
              value: v.jsonData.data.effectiveTime,
            };
            temp.push(obj2);
            this.contentList1[i] = temp;
          }
        });
      });
    },
    getHaveSend() {
      cmdGetRequestList(this.haveSendObject).then((res) => {
        this.haveSendList = res.data.data.list;
        // console.log("haveSend", this.haveSendList);
        // this.allCmdNum = this.allMsg.length;
        this.haveSendList.forEach((v, i) => {
          v.jsonData = JSON.parse(v.jsonData);
          if (v.jsonData.cmd == "setPasswd") {
            let temp = [];
            let obj = {
              name: "",
              value: "",
            };
            // console.log(v.jsonData);
            if (
              Object.prototype.hasOwnProperty.call(v.jsonData.data, "newPasswd")
            ) {
              (obj.name = "新密码"), (obj.value = v.jsonData.data.newPasswd);
            }

            temp.push(obj);
            this.contentList2[i] = temp;
          } else if (v.jsonData.cmd == "setToleranceMaxPower") {
            let temp = [];
            let obj = {
              name: "容差",
              value: v.jsonData.data.tolerance,
            };
            // (obj.name = "容差"), (obj.value = v.jsonData.data.tolerance);
            temp.push(obj);
            let obj1 = {
              name: "场站最大功率",
              value: v.jsonData.data.maxPower,
            };
            // (obj.name = "场站最大功率"),
            //   (obj.value = v.jsonData.data.maxPower);
            temp.push(obj1);
            this.contentList2[i] = temp;
          } else if (v.jsonData.cmd == "setPowerOutageDate") {
            let temp = [];
            let obj = {
              name: "",
              value: "",
            };
            (obj.name = "限电日期"),
              (obj.value = v.jsonData.data.powerOutageDate);
            temp.push(obj);
            this.contentList2[i] = temp;
          } else if (v.jsonData.cmd == "setDischargeChargePower") {
            let temp = [];
            let obj = {
              name: "限电允许用电功率",
              value: v.jsonData.data.dischargePower,
            };
            // (obj.name = "限电允许用电功率"),
            //   (obj.value = v.jsonData.data.usePower);
            temp.push(obj);
            let obj1 = {
              name: "限电最大充电功率",
              value: v.jsonData.data.chargePower,
            };
            // (obj.name = "限电最大充电功率"),
            //   (obj.value = v.jsonData.data.maxChargePower);
            temp.push(obj1);
            this.contentList2[i] = temp;
          } else if (v.jsonData.cmd == "setFeeConfig") {
            let temp = [];
            // let obj = {
            //   name: "费率标准",
            //   value: v.jsonData.data.rateId.name,
            // };
            // temp.push(obj);
            let obj1 = {
              name: "尖峰月份",
              value: v.jsonData.data.topPeakMonths.join(","),
            };
            // (obj1.name = "尖峰月份"),
            //   (obj1.value = v.jsonData.data.rateId.sharpTimeMonth);
            temp.push(obj1);
            // (obj2.name = "生效日期"),
            //   (obj2.value = v.jsonData.data.rateId.date);
            let obj2 = {
              name: "生效日期",
              value: v.jsonData.data.effectiveTime,
            };
            temp.push(obj2);
            this.contentList2[i] = temp;
          }
        });
      });
    },
    getUnSendBill() {
      billQuery(this.unSendObject).then((res) => {
        this.unSendBill = res.data.data.list;
        // console.log(this.unSendBill, "unBillMsg");
      });
    },
    getAllBill() {
      billQuery(this.object).then((res) => {
        this.allBillMsg = res.data.data.list;
        // console.log(this.allBillMsg, "allBillMsg");
      });
    },
    changeTotalCurrent(data) {
      this.object.pageNumber = data;
      this.currentPage = data;
      // console.log(this.object);
      this.getMessage();
    },
    changeTotalPage(data) {
      this.object.pageSize = data;
      this.pageSize = data;
      // console.log(this.object);
      this.getMessage();
    },
    changeUnSendCurrent(data) {
      this.unSendObject.pageNumber = data;
      this.currentPage = data;
      // console.log(this.object);
      this.getUnSend();
    },
    changeUnSendPage(data) {
      this.unSendObject.pageSize = data;
      this.pageSize = data;
      // console.log(this.object);
      this.getUnSend();
    },
    changeHaveSendCurrent(data) {
      this.haveSendObject.pageNumber = data;
      this.currentPage = data;
      // console.log(this.object);
      this.getHaveSend();
    },
    changeHaveSendPage(data) {
      this.haveSendObject.pageSize = data;
      this.pageSize = data;
      // console.log(this.object);
      this.getHaveSend();
    },
    viewDetail(data) {
      console.log(data);
      let userStatus = this.userId == data.userId ? false : true;
      this.$refs.rateDetail.open(
        data.jsonData.data,
        data.status,
        userStatus,
        data.id
      );
    },
    viewBill(data) {
      // console.log(data);
      this.$refs.billDetail.open(data);
    },
  },
};
</script>
<style lang="stylus" scoped>
.message-box{
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 130px);
  overflow: auto;
}
.message-content{
  background: #102135
  border-radius: 5px
  margin: 20px 0
  .message-list{
    padding:20px 0 30px 0
    .left{
      // width 10%
      margin: 0 100px
    }
  }
  .right{
      .status{
        float:right
      }
      .handle-button{
        margin:0 20px 20px 0
      }
    }
}
.img{
  width 70px
  height: 70px
  img{
    width 100%
    height: 100%
  }
}
.handle-button{
  text-align: center
}
</style>
